<template>
  <div class="echart" :id="chartId" :style="{ width: '100%', height: '20%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: "barP3",
  props: {
    chartId: String,
    sm: Number,
    jg: Number,
  },
  data(){
    return {
    }
  },
  mounted() {
    // this.initChart();
  },
  methods: {
    initChart(sm,jg) {
      let sum = sm+jg;
      jg = parseInt(jg/sum) * 100;
      sm = 100 - jg;
      let getchart = echarts.init(document.getElementById(this.chartId));
      let option = {

        xAxis: {
          type: 'value',
          max: 100,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          minorTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [{
          name: '机构',
          type: 'bar',
          stack: '1',
          label: {
            show: true,
            position: 'insideLeft',
            formatter: '{a}:{c}%',
            color: '#fff',
            fontSize: 14
          },
          color: '#e83d95',
          data:  [jg],
        },{
          name: '上门',
          type: 'bar',
          stack: '1',
          label: {
            show: true,
            position: 'insideRight',
            formatter: '{a}:{c}%',
            fontSize: 14
          },
          color: '#154490',
          data: [sm],
        },]
      };
      getchart.setOption(option);
    }
  }
}
</script>

<style scoped>

</style>