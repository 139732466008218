<template>
 <div style="width: 100%;height: 100%">

   <!--表 每日工单流水信息明细表 开始-->
   <div v-if="tableNumber === 0" style="font-size: 7px;width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: hidden;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
     <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
       <div style="width: 16.5%;" class="small-table-cell" >工单日期</div>
       <div style="width: 16.5%;" class="small-table-cell" >服务项目</div>
       <div style="width: 16.5%;" class="small-table-cell" >服务对象</div>
       <div style="width: 16.5%;" class="small-table-cell" >服务者</div>
       <div style="width: 16.5%;" class="small-table-cell" >服务机构</div>
<!--       <div style="width: 16.5%;" class="small-table-cell" >工单时长</div>-->
<!--       <div style="width: 16.5%;" class="small-table-cell" >开始时间</div>-->
<!--       <div style="width: 16.5%;" class="small-table-cell" >完成时间</div>-->
<!--       <div style="width: 16.5%;" class="small-table-cell" >异常标记</div>-->

     </div>
     <div style="height: calc(100% - 24px);overflow:hidden" id="mainTable">
       <div :class="{ 'animate-up-small-table': animateUp}" style="width: 100%;display: flex;color: #fff;justify-content: space-around;" v-for="(item, index) in dateDaily" :key="index">
         <div style="width: 16.5%;" class="small-table-cell">{{item.serviceDate}}</div>
         <div style="width: 16.5%;" class="small-table-cell">{{item.measuresNursingName}}</div>
         <div style="width: 16.5%;" class="small-table-cell">{{item.customerName}}</div>
         <div style="width: 16.5%;" class="small-table-cell">{{item.providerName}}</div>
         <div style="width: 16.5%;" class="small-table-cell">{{item.communityName}}</div>
<!--         <div style="width: 16.5%;" class="small-table-cell">{{item.duration}}</div>-->
<!--         <div style="width: 16.5%;" class="small-table-cell">{{item.start}}</div>-->
<!--         <div style="width: 16.5%;" class="small-table-cell">{{item.completion}}</div>-->
<!--         <div style="width: 16.5%;" class="small-table-cell">无</div>-->

       </div>
     </div>
   </div>
   <!--表 每日工单流水信息明细表 结束-->


   <!--    异常工单明细/日    -->
   <div v-if="tableNumber === 1" style="font-size: 7px;width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: hidden;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
     <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
       <div style="width: 16.66%;" class="small-table-cell">工单日期</div>
       <div style="width: 16.66%;" class="small-table-cell">服务项目</div>
       <div style="width: 16.66%;" class="small-table-cell">服务对象</div>
       <div style="width: 16.66%;" class="small-table-cell">服务者</div>
       <div style="width: 16.66%;" class="small-table-cell">服务机构</div>
<!--       <div style="width: 16.66%;" class="small-table-cell">工单时长</div>-->
       <!--                <div style="width: 4.01%; " class="small-table-cell" >超时</div>-->
       <!--                <div style="width: 4.01%; " class="small-table-cell" >缩时</div>-->
       <!--                <div style="width: 4.01%; " class="small-table-cell" >终止</div>-->
     </div>
     <div style="height: calc(100% - 24px);overflow-y: hidden;" id="smallTableTop">
       <div :class="{ 'animate-up-small-table': animateUp, 'alert-row':item.overtime > 66}" style="
              width: 100%;
              display: flex;
              color: #fff;
              justify-content: space-around;" v-for="(item, index) in dateDailyYc" :key="index">
         <div style="width: 16.66%;" class="small-table-cell">{{item.serviceDate}}</div>
         <div style="width: 16.66%;" class="small-table-cell">{{item.measuresNursingName}}</div>
         <div style="width: 16.66%;" class="small-table-cell">{{item.customerName}}</div>
         <div style="width: 16.66%;" class="small-table-cell">{{item.providerName}}</div>
         <div style="width: 16.66%;" class="small-table-cell">{{item.communityName}}</div>
<!--         <div style="width: 16.66%;" class="small-table-cell">{{item.duration}}</div>-->
         <!--                  <div style="width: 10.66%;" class="small-table-cell">{{item.start}}</div>-->
         <!--                  <div style="width: 10.66%;" class="small-table-cell">{{item.completion}}</div>-->
         <!--                  <div style="width: 4.01%; " class="small-table-cell">{{item.overtime > 66 ? '★' : ''}}</div>-->
         <!--                  <div style="width: 4.01%; " class="small-table-cell">{{item.overtime <= 33 ? '★' : ''}}</div>-->
         <!--                  <div style="width: 4.01%; " class="small-table-cell">{{item.overtime > 33 && item.stop <= 66 ? '★' : ''}}</div>-->
       </div>
     </div>
   </div>
   <!--    服务投诉明细/日    -->

   <!--表 服务投诉明细/日 开始-->
   <div v-if="tableNumber === 2" style="font-size: 7px;width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: hidden;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
     <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
       <div style="width: 16.66%;" class="small-table-cell" >投诉日期</div>
       <div style="width: 16.66%;" class="small-table-cell" >投诉项目</div>
       <div style="width: 16.66%;" class="small-table-cell" >投诉人</div>
<!--       <div style="width: 16.66%;" class="small-table-cell" >被投诉者</div>-->
       <div style="width: 16.66%;" class="small-table-cell" >被投诉机构</div>
<!--       <div style="width: 16.66%;" class="small-table-cell" >处理反馈</div>-->
       <!--                <div style="width: 14.28%;" class="small-table-cell" >备注情况信息</div>-->
     </div>
     <div style="height: calc(100% - 24px);overflow-y: hidden;" id="smallTableMiddle">
       <div :class="{ 'animate-up-small-table': animateUp}" style="
              width: 100%;
              display: flex;
              color: #fff;
              justify-content: space-around;" v-for="(item, index) in dateDailyTs" :key="index">
         <div style="width: 16.66%;" class="small-table-cell">{{item.complainTime}}</div>
         <div style="width: 16.66%;" class="small-table-cell">{{item.service}}</div>
         <div style="width: 16.66%;" class="small-table-cell">{{item.nickName}}</div>
<!--         <div style="width: 16.66%;" class="small-table-cell">{{item.nickName}}</div>-->
         <div style="width: 16.66%;" class="small-table-cell">{{item.communityName}}</div>
<!--         <div style="width: 16.66%;" class="small-table-cell">{{item.reason}}</div>-->
         <!--                  <div style="width: 14.28%;" class="small-table-cell">{{item.start}}</div>-->

       </div>
     </div>
   </div>
   <!--表 服务投诉明细/日 结束-->

   <!--表 高频高额自费项目明细 开始-->
   <div v-if="tableNumber === 3" style="font-size:7px;width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: hidden;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
     <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
       <div style="width: 20.66%;" class="small-table-cell" >工单日期</div>
       <div style="width: 20.66%;" class="small-table-cell" >服务项目</div>
       <!--                <div style="width: 16.66%;" class="small-table-cell" >服务对象</div>-->
       <div style="width: 20.66%;" class="small-table-cell" >服务者</div>
       <div style="width: 20.66%;" class="small-table-cell" >服务机构</div>
       <!--                <div style="width: 16.66%;" class="small-table-cell" >自费金额</div>-->
       <div style="width: 8.66%;" class="small-table-cell" >高额</div>
       <div style="width: 8.66%;" class="small-table-cell" >高频</div>
     </div>
     <div style="height: calc(100% - 24px);overflow-y: hidden;" id="smallTableBottom">
       <div :class="{ 'animate-up-small-table': animateUp}" style="width: 100%;display: flex;color: #fff;justify-content: space-around;" v-for="(item, index) in dateDailyGPGE" :key="index">
         <div style="width: 20.66%;" class="small-table-cell">{{item.date}}</div>
         <div style="width: 20.66%;" class="small-table-cell">{{item.service}}</div>
         <!--                  <div style="width: 16.66%;" class="small-table-cell">{{item.consumers}}</div>-->
         <div style="width: 20.66%;" class="small-table-cell">{{item.provider}}</div>
         <div style="width: 20.66%;" class="small-table-cell">{{item.org}}</div>
         <!--                  <div style="width: 16.66%;" class="small-table-cell">{{item.selfPay}}</div>-->
         <div style="width: 8.66%;" class="small-table-cell">{{item.highExpense}}</div>
         <div style="width: 8.66%;" class="small-table-cell">{{item.highExpense2}}</div>

       </div>
     </div>
   </div>
   <!--表 高频高额自费项目明细 结束-->

 </div>

</template>

<script>
export default {
  name: "gpgezfxmmx",
  props: {
    dateDaily: Array,
    dateDailyYc: Array,
    dateDailyTs: Array,
    dateDailyGPGE: Array,
    animateUp: Boolean,
    tableNumber: Number
  },
  methods: {
    mouseAction(flag) {
      // if(flag === 1) {
      //   this.timer1 = setInterval(this.scrollAnimate, 1500);
      // } else {
      //   clearInterval(this.timer1)
      // }
      this.$parent.mouseAction(flag)
    },
  }
}
</script>

<style scoped>
.animate-up-small-table {
  transition: all 0.5s ease-in-out;
  transform: translateY(-30px);
}
.small-table-cell {
  height: 30px;line-height: 30px;text-align: center;
  padding-left: 5px;
  white-space: nowrap;
}
.alert-row {
  background-color: #b10000;
}
</style>