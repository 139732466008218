import server from '../../util/server';
import context from "../../util/context";

/**
 * 中间顶部
 * @returns {*}
 */
export function getTwoTopList() {
    return server.get({
        url: context.baseUrl + '/base/orderConnection/getOneOrderList',
    });
}

/**
 * 中间饼图
 * @returns {Promise<unknown>}
 */
export function getTwoTopBtList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoTopBtList',
    });
}

/**
 * 左侧趋势图 月
 * @returns {Promise<unknown>}
 */
export function getTwoRightList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoRightList',
    });
}

/**
 * 左侧趋势图 年
 * @returns {Promise<unknown>}
 */
export function getTwoRightYearList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoRightYearList',
    });
}

/**
 * 工单流水
 * @returns {Promise<unknown>}
 */
export function getTwoGdlsList() {
    return server.get({
        url: context.baseUrl + '/base/orderConnection/getOrderConnectionList',
    });
}

export function getTwoGdlsListGpGe() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getTwoGdlsList',
    });
}

export function getSkillDurationList() {
    return server.get({
        url: context.baseUrl + '/base/orderConnection/getSkillDurationList',
    });
}

/**
 * 异常工单
 * @returns {Promise<unknown>}
 */
export function getYcOrderList() {
    return server.get({
        url: context.baseUrl + '/base/orderConnection/getYcOrderList',
    });
}


/**
 * 投诉工单
 * @returns {Promise<unknown>}
 */
export function getTsOrderList() {
    return server.get({
        url: context.baseUrl + '/base/orderConnection/getTsOrderList',
    });
}

/**
 * 服第三屏左侧饼图方法
 * @returns {Promise<unknown>}
 */
export function getThreeLeftList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getThreeLeftList',
    });
}

/**
 * 柱状图
 * @returns {Promise<unknown>}
 */
export function getThreeRightList() {
    return server.get({
        url: context.baseUrl + '/base/onerightone/getThreeRightList',
    });
}